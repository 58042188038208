.channelContainer {
    padding: 1rem;
    border-radius: 1rem;
    margin-right: 1rem;
    aspect-ratio: 126 / 187;
    background-color: #f8f8db;
    max-height: 500px;
    margin-bottom: 1rem;
    p {
      font-family: Aleo;
      font-weight: bold;
    }
}
.channelsContainer {
    display: flex;
    overflow: auto;
}
.channelHeaderEdit {
  display: flex;
}
.channelInput {
  max-width: 240px;
}
.iconContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
}
.iconDropdown {
  width: 100% !important;
  padding-left: 10px !important;
  //background-color: red !important;
}

.channelLink {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.75);
  &:hover {
    color:black;
    i {
      transition: all 0.1s !important;
    }
    p {
      text-decoration: underline;
    }
  }
}
.removeButton {
  padding:15px !important;
  i {
    margin-right:0 !important;
  }
}